<template>
	<!-- 保证金等级 -->
	<div class="storeBondLevel el-content">
		<router-link to="/adhibition/store/bond_level_edit">
			<a-button class="mb12" type="primary"><i class="ri-add-line ri-lg ri-top"></i>新增等级</a-button>
		</router-link>
		<a-table :pagination="false" row-key="level_id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'level_id'},
			{title:'等级名称',dataIndex:'level_name',slots:{customRender:'level_name'}},
			{title:'最低保证金金额',dataIndex:'where_min_amount',slots:{customRender:'where_min_amount'}},
			{title:'等级描述',dataIndex:'level_desc'},
			{title:'创建时间',dataIndex:'create_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]">
			<template #level_name="{record}">
				<div style="display: flex;align-items: center;">
					<img :src="record.level_logo" style="width: 30px;height: 30px;margin-right: 5px;" alt="">
					<span>{{record.level_name}}</span>
				</div>
			</template>
			<template #where_min_amount="{record}">
				<span class="kdred">{{ record.where_min_amount }}元</span>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/adhibition/store/bond_level_edit',query:{level_id:record.level_id}}">
						<kd-button type="primary" 
							title="编辑" 
							icon="ri-edit-line" 
							v-has="{action:'/adhibition/store/bond_level_edit'}">
						</kd-button>
					</router-link>
					<kd-button type="danger" 
						title="删除" 
						icon="ri-delete-bin-5-line" 
						v-has="{action:'wxapp_marketing_store_bond_level_del'}"  
						@click="deleteLevel(record.level_id)">
					</kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getLevelList(info.page,e)}"
				@change="(e)=>{getLevelList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import shopModel from '@/api/addons/shop'
export default{
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		getBondLevel(1,_d.info.limit)
		function getBondLevel( page,limit ){
			shopModel.getBondLevel(page,limit,res=>_d.info = {limit,...res})
		}
		const deleteLevel = (id)=>shopModel.deleteBondLevel(id,()=>getBondLevel(_d.info.page,_d.info.limit))
		return{
			...toRefs(_d),
			getBondLevel,
			deleteLevel
		}
	}
}
</script>

<style>
</style>
